import API from './API'
import Csrf from './Csrf'

export default class Donasi {

    async getList() {
        await Csrf.getCookie()
        return API.get(`/donasi`)
    }

    async store(form) {
        await Csrf.getCookie()
        return API.post('/donasi', form)
    }

    async get(id) {
        await Csrf.getCookie()
        return API.get(`/donasi/${id}`)
    }

    async getTerkini(year, month, day) {
        await Csrf.getCookie()
        return API.get(`/donasi/date/${year}-${month}-${day}/week`)
    }

    async getDashboard(year, month, day) {
        await Csrf.getCookie()
        return API.get(`/dashboard/${year}-${month}-${day}`)
    }

    async getYear(year) {
        await Csrf.getCookie()
        return API.get(`/donasi/date/${year}`)
    }

    async getMonth(year, month) {
        await Csrf.getCookie()
        return API.get(`/donasi/date/${year}-${month}`)
    }

    async getDay(year, month, day) {
        await Csrf.getCookie()
        return API.get(`/donasi/date/${year}-${month}-${day}`)
    }
    
    async getWeek(year, month, day) {
        await Csrf.getCookie()
        return API.get(`/donasi/date/${year}-${month}-${day}/week`)
    }

    async getDaySum(year, month, day) {
        await Csrf.getCookie()
        return API.get(`/donasi/total/${year}-${month}-${day}`)
    }

    async getWeekSum(year, month, day) {
        await Csrf.getCookie()
        return API.get(`/donasi/total/${year}-${month}-${day}/week`)
    }

    async getMonthSum(year, month) {
        await Csrf.getCookie()
        return API.get(`/donasi/total/${year}-${month}`)
    }

    async getYearSum(year) {
        await Csrf.getCookie()
        return API.get(`/donasi/total/${year}`)
    }

    async getYearMonthlySum(year) {
        await Csrf.getCookie()
        return API.get(`/donasi/total/${year}/bulanan`)
    }
}