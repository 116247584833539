import axios from 'axios'

let API = axios.create({
    baseURL: `https://amil.siskeubq.my.id/api`,
    // baseURL: `http://192.168.0.105/api`,
});

API.defaults.withCredentials = true

export default API;
