<template>
	<div class="grid fadein animation-duration-300">
		<div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0 cursor-pointer" @click="searchTanggal">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Donasi 7 Hari Terakhir</span>
						<div class="text-900 font-medium text-xl">{{ formatCurrency(donasiMingguIni) }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-check-square text-blue-500 text-xl"></i>
					</div>
				</div>
				<div>
					<span class="text-500">Ada </span>
					<span class="text-green-500 font-medium">{{ jumlahDonasiMingguIni }} donasi </span>
					<span class="text-500">minggu ini</span>
				</div>
				<div>
					<small><i>*pilih untuk melihat daftar lengkap</i></small>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0 cursor-pointer" @click="searchBulan">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Donasi Bulan {{ namaBulanSekarang }}</span>
						<div class="text-900 font-medium text-xl">{{ formatCurrency(donasiBulanIni) }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-check-square text-orange-500 text-xl"></i>
					</div>
				</div>
				<div>
					<span class="text-500">Ada </span>
					<span class="text-green-500 font-medium">{{ jumlahDonasiBulanIni }} donasi </span>
					<span class="text-500">bulan ini</span>
				</div>
				<div>
					<small><i>*pilih untuk melihat daftar lengkap</i></small>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0 cursor-pointer" @click="searchTahun">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Donasi Tahun {{ tahunSekarang }}</span>
						<div class="text-900 font-medium text-xl">{{ formatCurrency(donasiTahunIni) }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-check-square text-cyan-500 text-xl"></i>
					</div>
				</div>
				<div>
					<span class="text-500">Ada </span>
					<span class="text-green-500 font-medium">{{ jumlahDonasiTahunIni }} donasi </span>
					<span class="text-500">tahun ini</span>
				</div>
				<div>
					<small><i>*pilih untuk melihat daftar lengkap</i></small>
				</div>
			</div>
		</div>
	<div class="col-12 xl:col-6">
		<div class="card">
			<div class="flex flex-wrap justify-content-between flex-row grid">
				<div class="flex flex-column justify-content-start col">
					<h5 class="mt-auto mb-auto">Donsai Terbaru</h5>
					<small class="mt-auto mb-auto"><i>*pilih untuk mencetak kwitansi</i></small>
				</div>
				<div class="flex justify-content-end col">
					<Button label="Input donasi baru" icon="pi pi-pencil" class="p-button-text" @click="newDonasi"></Button>
				</div>
			</div>
			<DataTable 	:value="donasiTerkini" :rows="5" :paginator="false" responsiveLayout="scroll"
						:loading="loading" stripedRows sortField="id" :sortOrder="-1"
						selectionMode="single" @rowSelect="cetakKwitansi" v-model:selection="selectedDonasi">
				<Column field="tgl_donasi" header="Jam" :sortable="true" style="width:10%">
					<template #body={data}>
						{{formatTime(data.tgl_donasi)}}
					</template>
				</Column>
				<Column field="id" header="Nomor Kwitansi" :sortable="true" style="width:15%">
					<template #body={data}>
						{{noKwitansi(data.id)}}
					</template>
				</Column>
				<Column field="tgl_donasi" header="Tanggal Donasi" :sortable="true" style="width:25%">
					<template #body={data}>
						{{formatDate(data.tgl_donasi)}}
					</template>
				</Column>
				<Column field="nama_donatur" header="Nama Donatur" :sortable="true" style="width:25%" />
				<Column field="nominal" header="Nominal" :sortable="true" style="width:25%">
					<template #body={data}>
						{{formatCurrency(data.nominal)}}
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
	<div class="col-12 xl:col-6">
		<div class="card">
			<h5>Perkembangan Donasi di tahun {{ tahunSekarang }}</h5>
			<Chart type="line" :data="lineData" :options="lineOptions" ref="chart"/>
		</div>
	</div>
	</div>
</template>

<script>
	import Donasi from '../api/Donasi'

	export default {
		created() {
			this.Donasi = new Donasi();
		},
		data() {
			return {
				donasiTerkini: null,
				donasiMingguIni: null,
				jumlahDonasiMingguIni: null,
				donasiBulanIni: null,
				jumlahDonasiBulanIni: null,
				donasiTahunIni: null,
				jumlahDonasiTahunIni: null,
				jumlahDonasiTahunIniBulanan: [0,0,0,0,0,0,0,0,0,0,0,0],
				tahunSekarang: new Date().getFullYear(),
				bulanSekarang: new Date().getMonth()+1,
				namaBulanSekarang: new Date().toLocaleDateString('id-ID', {month: 'long'}),
				tanggalSekarang: new Date().getDate(),
				loading: false,
				lineData: {},
				lineOptions: null,
				selectedDonasi: null,
			}
		},
		beforeMount() {
			this.loading = true;
			Promise.all([this.Donasi.getDashboard(this.tahunSekarang, this.bulanSekarang, this.tanggalSekarang), this.Donasi.getYearMonthlySum(this.tahunSekarang)])
                    .then(response => {
						this.donasiTahunIni = response[0].data.sum[0]
						this.jumlahDonasiTahunIni = response[0].data.count[0]
						this.donasiBulanIni = response[0].data.sum[1]
						this.jumlahDonasiBulanIni = response[0].data.count[1]
						this.donasiMingguIni = response[0].data.sum[2]
						this.jumlahDonasiMingguIni = response[0].data.count[2]
                        this.donasiTerkini = response[0].data.donasi
						this.donasiTerkini.forEach(donasi => donasi.tgl_donasi = new Date(donasi.tgl_donasi));
						//getYearMonthly()
						this.jumlahDonasiTahunIniBulanan = response[1].data.sum;
						this.initChart();
						this.loading = false;
                    })
                    .catch(error => {
						console.log(error.response);
                    });
		},
		beforeUnmount() {
		},
		methods: {
			initChart() {
				this.lineData = {
					labels: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
					datasets: [
						{
							label: 'Donasi (Rupiah)',
							data: this.jumlahDonasiTahunIniBulanan,
							fill: false,
							backgroundColor: '#0a75ad',
							borderColor: '#0a75ad',
							tension: 0.1
						},
					]
				};
			},
			formatDate(value) {
				return value.toLocaleDateString('id-ID', {
					day: '2-digit',
					month: 'short',
					year: 'numeric',
				});
			},
			formatTime(value) {
				return Intl.DateTimeFormat('id-ID', {hour:'numeric', minute:'numeric'}).format(value);
			},
			formatCurrency(value) {
				var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
					maximumFractionDigits: 0,
				});
				return formatter.format(value);
			},
			noKwitansi(value) {
                return ('0000000' + value).substr(-8);
            },
			searchTanggal() {
				this.$store.commit("setTanggal", this.tanggalSekarang);
				this.$store.commit("setBulan", this.bulanSekarang);
				this.$store.commit("setTahun", this.tahunSekarang);
				this.$router.push('/donasi')
			},
			searchBulan() {
				this.$store.commit("deleteTanggal");
				this.$store.commit("setBulan", this.bulanSekarang);
				this.$store.commit("setTahun", this.tahunSekarang);
				this.$router.push('/donasi')
			},
			searchTahun() {
				this.$store.commit("deleteTanggal");
				this.$store.commit("deleteBulan");
				this.$store.commit("setTahun", this.tahunSekarang);
				this.$router.push('/donasi')
			},
			cetakKwitansi(event) {
				this.$router.push({name: 'Kwitansi', params: {idDonasi: event.data.id} })
            },
			newDonasi() {
				this.$router.push('/input_donasi')
			}
		}
	}
</script>
