<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>
		<router-link to="/" class="flex flex-column sm:flex-row layout-topbar-logo">
			<img alt="Logo" src="/layout/images/logo.png" />
			<span>BADAN AMIL</span>
		</router-link>
		

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button" @click="setting">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
				<Menu id="settting_menu" ref="menu1" :model="settingMenu" :popup="true" />
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="profile" aria-haspopup="true" aria-controls="overlay_menu">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
				<Menu id="profile_menu" ref="menu2" :model="profileMenu" :popup="true" />
			</li>
		</ul>
	</div>
	<Toast></Toast>
</template>

<script>
import User from './api/User.js';
import router from './router';

export default {
    emits: ['changeTheme', 'menuToggle'],
	data() {
        return {
			scale: localStorage.fontSize,
			scales: [10,11,12,13,14,15,16,17,18,19,20],
            profileMenu: [
                {
                    label: "Profile",
                    items: [
					{
                        label: 'Data diri',
                        icon: 'pi pi-user',
                        command: () => {
							router.push('/profile');
                        }
                    },
                    {
                        label: 'Ganti Password',
                        icon: 'pi pi-lock',
                        command: () => {
                            router.push('/gantiPassword');
                        }
                    },
                    {
                        label: 'Keluar',
                        icon: 'pi pi-sign-out',
                        command: () => {
                            this.signout()
                        }
                    }]
                },
			],
			settingMenu: [
                {
                    label: "Setting",
                    items: [
					{
                        label: () => {
							if (localStorage.appDark == "true") {
								return 'Mode Malam OFF';
							} else if (localStorage.appDark == "false") {
								return 'Mode Malam ON';
							}
						},
                        icon: 'pi pi-sun',
                        command: () => {
							if (localStorage.appDark == "false") {
								this.$emit('changeTheme', {theme: 'lara-dark-indigo', dark: true});
							} else {
								this.$emit('changeTheme', {theme: 'lara-light-indigo', dark: false});
							}
                        }
                    },
                    {
                        label: 'Perbesar Font',
                        icon: 'pi pi-plus',
                        command: () => {
							this.incrementScale();
                        }
                    },
					{
						label: 'Kecilkan Font',
                        icon: 'pi pi-minus',
                        command: () => {
							this.decrementScale();
                        }
					}]
                },
			]
		}
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menuToggle', event);
        },
		
		signout() {
            User.logout();
			router.push('login');
        },
		profile(event) {
			this.$refs.menu2.toggle(event);
        },
		setting(event) {
			this.$refs.menu1.toggle(event);
        },
		decrementScale() {
			if (this.scale >= 15) {
				this.scale--;
				this.applyScale();
			}
		},
		incrementScale() {
			if (this.scale <= 20) {
				this.scale++;
				this.applyScale();
			}
		},
		applyScale() {
			document.documentElement.style.fontSize = this.scale + 'px';
			localStorage.fontSize = this.scale;
		},
    },
	computed: {
		darkTheme() {
			return localStorage.appDark;
		}
	}
}
</script>
