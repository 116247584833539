<template>
    <Login v-if="$route.path === '/login'"/>
    <Error v-else-if="$route.path === '/error'" />
    <Forbidden v-else-if="$route.path === '/forbidden'" />
    <NotFound v-else-if="$route.path === '/notfound'" />
    <App v-else @changeTheme="darkMode" />
</template>

<script>
import EventBus from './AppEventBus';
import App from './App';
import Login from './pages/Login';
import Error from './pages/Error';
import NotFound from './pages/NotFound';
import Forbidden from './pages/Forbidden';

export default {
    data() {
        return {
        }
    },
    methods: {
        darkMode(event) {
            let themeElement = document.getElementById('theme-link');
            themeElement.setAttribute('href', themeElement.getAttribute('href').replace(localStorage.appTheme, event.theme));
            localStorage.appTheme = event.theme;
            localStorage.appDark = event.dark;
            EventBus.emit('changeTheme', event);
        }
    },
    components: {
        App,
        Login,
        Error,
        NotFound,
        Forbidden,
    }
}
</script>