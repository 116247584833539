<template>
    <toast />
    <ConfirmDialog class="bg-orange-300"></ConfirmDialog>
    <div class="flex align-content-center flex-wrap card-container h-screen fadein">
        <div class="p-4 w-full h-screen lg:w-6 m-auto opacity-80 overflow-hidden" id="main"></div>
        <div  class="surface-card p-4 shadow-3 w-full lg:w-6 m-0">
            <div class="my-8">
                <div class="text-center mb-5">
                    <img src="/layout/images/logo.png" alt="Image" height="100" class="mb-3">
                    <div class="text-blue-700 text-5xl font-semibold mb-3">Badan Amil<br>Baitul Qur'an</div>
                </div>
                <div class="login-panel">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import User from '../api/User'

    export default {
        name: 'Login',
        
        data() {
            return {
                form: {
                    email: '',
                    password: ''
                },
                errors: {},
                loadingLogin: false,
                successLogin: false,
                errorLogin: false,
            }
        },
        methods: {
            login() {
                this.loadingLogin = true
                User.login(this.form)
                    .then(response => {
                        this.reset()
                        this.successLogin = true
                        this.$router.push({path: '/'})
                    })
                    .catch(error => {
                        this.errorLogin = true
                        if (!error.response) {
                            this.networkError()
                        } else if(error.response.status == 422) {
                            this.errors = error.response.data.errors
                            if (this.errors.email) {
                                this.$toast.add({severity:'error', summary: 'Error', detail: this.errors.email[0], life: 3000});
                            }
                            if (this.errors.password) {
                                this.$toast.add({severity:'error', summary: 'Error', detail: this.errors.password[0], life: 3000});
                            }
                        }
                    }).finally(() => {
                        this.loadingLogin =  false
                    });
            },
            reset(){
                this.successLogin = false;
                this.errorLogin = false;
                for(let field in this.form){
                    this.form[field] = null;
                }
            },
            showError() {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
            },
            networkError() {
            this.$confirm.require({
                message: 'Tidak bisa menghubungi server. Coba refresh halaman.',
                header: 'Network error',
                icon: 'pi pi-exclamation-triangle',
                blockScroll: true,
                defaultFocus: "accept",
                accept: () => {
                    this.$router.go()
                },
                reject: () => {
                    this.$confirm.close();
                }
            });
        },
        },
        mounted() {
        }
    }
</script>

<style>
#main {
    background-image: url("/layout/images/placeholder-photo.jpg");
    background-size: cover;
    background-position: center;
}
fieldset {
    border: none;
}
#axiosForm{  
    position: relative;
}
.loader{  
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#eceaea;
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
}
</style>