import { createStore } from 'vuex'

const store = createStore({
    state () {
      return {
        user: {},
        tahun: null,
        bulan: null,
        tanggal: null,
      }
    },
    mutations: {
      getUser(state, data) {
        state.user = data
      },
      setTahun(state, tahun) {
        state.tahun = tahun
      },
      setBulan(state, bulan) {
        state.bulan = bulan
      },
      setTanggal(state, tanggal) {
        state.tanggal = tanggal
      },
      deleteTanggal(state) {
        state.tanggal = null
      },
      deleteBulan(state) {
        state.bulan = null
      },
      deleteTahun(state) {
        state.tahun = null
      },
    }
  })

export default store;