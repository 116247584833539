import API from './API'
import Csrf from './Csrf'

export default {
    async login(form) {
        await Csrf.getCookie()

        return API.post('/login', form)
    },

    async logout() {
        await Csrf.getCookie()

        return API.post('/logout')
    },

    async register(form) {
        await Csrf.getCookie()
        return API.post('/register', form)
    },

    check() {
        return API.get('/check')
    },
    auth() {
        return API.get('/user')
    },

    async changePassword(form) {
        await Csrf.getCookie()

        return API.post('/changePassword', form)
    },
    async cron() {
        return API.get('/cron/refresh/mv')
    },

    async amil() {
        return API.get('/amil')
    }
}