<template>
	<div class="layout-footer flex justify-content-center flex-column text-center">
		<strong>Copyright &copy; {{ year }} <a href="https://baitulquran.or.id">Pondok Pesantren Baitul Quran</a>.&nbsp;All rights reserved.&nbsp;</strong>
		<div class="float-right d-none d-sm-inline-block">
			<strong>Version</strong> {{ version }}
		</div>
	</div>
</template>

<script>
import {version} from '../package.json';
	export default {
		name: "AppFooter",
		data() {
			return {
				year: new Date().getFullYear(),
				version: version
			}
		}
		
	}
</script>