<template>
    <PageLoader v-if="Loading"/>
	<div :class="containerClass" @click="onWrapperClick" v-if="!Loading">
        <AppTopBar @menuToggle="onMenuToggle" @changeTheme="darkMode" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container" >
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import PageLoader from './PageLoader';
import User from './api/User.js';


export default {
    emits: ['changeTheme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            Loading: true,
            menu : [
                {
                    label: 'Menu',
                    items: [
                        {label: 'Dashboard', icon: 'fa-solid fa-home', to: '/'},
						{label: 'Amil', icon: 'fa-solid fa-users', to: '/daftaramil', visible: () => (this.isAdmin() || this.isSuperAdmin())},
						{label: 'Donatur', icon: 'fa-solid fa-book-open-reader', to: '/daftar_donatur', visible: () => (this.isAmil() || this.isAdmin() || this.isSuperAdmin())},
						
                        {
                            label: 'Donasi', icon: 'fa-solid fa-book',
                            items: [
                                {label: 'Tambah Donasi', icon: 'fa-solid fa-square-pen', to: '/input_donasi', visible: () => (this.isAmil() || this.isAdmin() || this.isSuperAdmin())},
                                {label: 'Laporan Donasi', icon: 'fa-solid fa-book-open', to: '/donasi', visible: () => (this.isAmil() || this.isAdmin() || this.isSuperAdmin())},
                                
                            ]
                        },
						{label: 'Monitoring', icon: 'fa-solid fa-users', to: '/monitoring', visible: () => (this.isSuperAdmin())},
                    ]
                },
                {
                    separator: true
                },
                {
                            label: 'Menu',
                }
				
            ]
        }
    },
    created() {
        User.auth()
            .then(response => {
                this.Loading = false
                this.$store.commit("getUser", response.data)
            }).catch(error => {
                this.$router.push('/login');
            })
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        isAmil() {
            if (this.$store.state.user.role == "Amil Senior") {
                return true
            }
        },
        isAdmin() {
            if (this.$store.state.user.role == "Admin") {
                return true
            }
        },
        isSuperAdmin() {
            if (this.$store.state.user.role == "Super Admin") {
                return true
            }
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        darkMode(event) {
            this.$emit('changeTheme', event);
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (localStorage.appDark) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
        'PageLoader': PageLoader,
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
