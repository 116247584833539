import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

import {authenticated} from './api/middleware/Auth'

const routes = [
    {
        path: '/login',
        name: 'Login', 
        component: () => import('./components/LoginPanel.vue'),
        meta: {
            guestOnly: true,
            title: 'Login'
        }
    },
    {
        path: '/kwitansi/:idDonasi',
        name: 'Kwitansi',
        component: () => import('./components/Kwitansi.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            title: 'Kwitansi',
        }
    },
    {
        path: '/gantiPassword',
        name: 'GantiPassword',
        component: () => import('./components/GantiPassword.vue'),
        meta: {
            requiresAuth: true,
            title: 'Ganti Password',
        }
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            title: 'Dashboard'
        }
    },
    {
        path: '/daftar_donatur',
        name: 'DaftarDonatur',
        component: () => import('./components/DaftarDonatur.vue'),
        meta: {
            requiresAuth: true,
            title: 'Daftar Donatur',
        }
    },
    {
        path: '/donatur/:idDonatur',
        name: 'DetailDonatur',
        component: () => import('./components/DetailDonatur.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            title: 'Detail Donatur',
        }
    },
    {
        path: '/donasi',
        name: 'Donasi',
        component: () => import('./components/LaporanDonasi.vue'),
        meta: {
            requiresAuth: true,
            title: 'Donasi',
        }
    },
    {
        path: '/input_donasi',
        name: 'InputDonasi',
        component: () => import('./components/InputDonasi.vue'),
        meta: {
            requiresAuth: true,
            title: 'Input Donasi',
        }
    },
    {
        path: '/donasi/berhasil/:idDonasi',
        name: 'DonasiSukses',
        component: () => import('./components/DonasiSukses.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            title: 'Donsai Berhasil',
        }
    },
    
    {
        path: '/daftaramil',
        name: 'DaftarAmil',
        component: () => import('./components/DaftarAmil.vue'),
        meta: {
            requiresAuth: true,
            title: 'Daftar Amil',
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('./components/Profile.vue'),
        meta: {
            requiresAuth: true,
            title: 'Profile',
        }
    },
    {
        path: '/monitoring',
        name: 'Monitoring',
        component: () => import('./components/Monitoring.vue'),
        meta: {
            requiresAuth: true,
            title: 'Monitoring',
        }
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: () => import('./pages/Forbidden.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from) => {
    if (to.meta.requiresAuth && !authenticated) {
      return {
        path: '/login',
        query: { redirect: to.fullPath },
      }
    }
  })
router.afterEach((toRoute, fromRoute) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home'
})

export default router;
